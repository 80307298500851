import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import Layout from "~/components/Layout"
import Wrapper from "~/components/reusable/Wrapper"
import PreviewImage from "~/components/reusable/PreviewImage"
import useTemplateContent from "~/hooks/useTemplateContent"
import { SUBMIT_FORM } from "~/components/formFields/GravityForms"
import GravityForm from "~/components/reusable/GravityForm"
import { isBrowser } from "~/utils/helpers"
import Spacer from "~/components/reusable/svgs/Spacer"
import Seo from "~/components/SEO"
import parse from "html-react-parser"
import Dot from "~/components/reusable/Dot"

export default function Online() {
  if (!isBrowser()) {
    return null
  }
  const [joinUsAssurance, signUpJoinUs] = useTemplateContent([
    "joinUsAssurance",
    "signUpJoinUs",
  ])

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")
  const [postcode, setPostcode] = useState("")
  const formId = useTemplateContent("signupForm")
  const assurance = useTemplateContent("joinUsAssurance")
  const meta = useTemplateContent("joinUsMeta")

  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM, {
    onCompleted: data => {
      setFormSubmitted(true)
      navigate("/join-us/thank-you", {
        state: {
          formSubmitted: true,
          emailAddress: emailAddress,
          signupType: "online",
          postcode: postcode,
        },
      })
    },
    onError: error => {
      console.log(error)
      setFormSubmitted(true)
      navigate("/join-us/thank-you", {
        state: {
          formSubmitted: true,
          emailAddress: emailAddress,
          signupType: "online",
          postcode: postcode,
        },
      })
    },
  })

  return (
    <Layout path="/join-us/outline/">
      <Seo title={meta?.title} description={meta?.description} />
      <Wrapper className={``}>
        <Dot />
        <h1 className="flex-set-0-0 lg: mx-auto basis-full px-3 py-10 text-h2 font-bold md:pt-[72px] md:pb-14 md:text-h1 lg:basis-5/6">
          {signUpJoinUs.pageTitle}
        </h1>

        <div
          className={`flex-set-0-0 vs-shadow relative z-[2] mx-auto basis-almost-full rounded-[32px] bg-theme-green-lighter p-8 md:px-24 md:py-16 lg:basis-almost-5/6`}
        >
          <header>
            <h2 className={`mb-2 text-h4 font-bold md:text-h3`}>
              {signUpJoinUs.title}
            </h2>
            <p className={`mb-10 text-small md:mb-16`}>{signUpJoinUs.text}</p>
          </header>
          <GravityForm
            formId={formId}
            submitFunction={submitForm}
            loading={loading}
            error={error}
            setEmailAddress={setEmailAddress}
            setPostcode={setPostcode}
          />
        </div>
      </Wrapper>

      <div className="extra relative z-[-1] mt-[-30px]">
        <Spacer top={`transparent`} bottom={`orange-lighter`} />
        <div className={`bg-theme-orange-lighter py-10`}>
          <Wrapper className={`md:items-center`}>
            <div
              className={`con-wrap flex-set-0-0 basis-full py-10 md:basis-1/2 lg:mx-1/12 lg:basis-1/3`}
            >
              <h3
                className={`mb-5 px-3 text-h4 font-bold leading-none text-theme-black md:mb-8 md:text-h3`}
              >
                {assurance?.title}
              </h3>
              <p className={`mb-5 px-3 text-normal leading-8 text-theme-black`}>
                {parse(assurance?.text)}
              </p>
              <p
                className={`px-3 text-caption font-black uppercase tracking-[0.2em]`}
              >
                {assurance?.name} {assurance.role ? `- ${assurance?.role}` : ""}
              </p>
            </div>
            <PreviewImage
              alt={assurance?.image?.id}
              fallbackUrl={assurance?.image?.sourceUrl}
              image={assurance?.image?.localFile}
              className={`flex-set-0-0 mx-auto basis-5/6 md:mx-0 md:ml-1/12 md:basis-5/12 lg:ml-0 lg:basis-1/2`}
            />
          </Wrapper>
        </div>
      </div>
      <div className={`mt-[-90px]`}>
        <Spacer top={`transparent`} bottom={`black`} />
      </div>
    </Layout>
  )
}
